import React, { FC } from "react";
import ButtonPrimary from "@/shared/Button/ButtonPrimary";
import HeroSearchForm from "@/components/HeroSearchForm/HeroSearchForm";
import LocationInput from "../HeroSearchForm/LocationInput";
import StayDatesRangeInput from "../HeroSearchForm/StayDatesRangeInput";
import { DateRage } from "../HeroSearchForm/StaySearchForm";
import { useNavigate } from "react-router-dom";
import { DATE_FORMAT } from "@/contains/contants";
import moment from "moment";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero2: FC<SectionHeroProps> = ({ className = "" }) => {

  const [location, setLocation] = React.useState<string>('')
  const [dateRange, setDateRange] = React.useState<DateRage>({startDate:moment(),endDate:moment()})
  const navigate = useNavigate()

  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-0 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
          <h1 className="font-medium text-4xl md:text-5xl xl:text-7xl !leading-[114%] my-4">
            Book a billboard in few clicks!
          </h1>
          <h3 className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
          Say goodbye to phone calls - book digital billboards online instantly
          </h3>
          <div className="bg-white w-full rounded-3xl lg:hidden">
            <LocationInput defaultValue={location} onChange={setLocation}/>
            <StayDatesRangeInput defaultValue={dateRange} onChange={setDateRange}/>
          </div>
          <ButtonPrimary className="lg:hidden" onClick={()=>{
            navigate('/billboards/'+location+'?startDate='+dateRange.startDate?.format(DATE_FORMAT)+'&endDate='+dateRange.endDate?.format(DATE_FORMAT)+window.location.hash)
          }}>Start Booking Now</ButtonPrimary>
          <ButtonPrimary className="hidden lg:block" onClick={()=>{
            const element=document.getElementById('HeroSearchForm')
            if(element?.offsetParent){
              const y = element.getBoundingClientRect().top + window.pageYOffset -100; 
              window.scrollTo({top: y, behavior: 'smooth'});
            }else{
              PubSub.publish('START_SEARCH_CLICK')
            }
          }}>Start Booking Now</ButtonPrimary>

        </div>
        {/* <HeroSearchForm /> */}
        
        <div className="flex-grow mt-8 lg:mt-0 lg:block rounded-3xl overflow-hidden ">
          <img className="w-full hidden lg:block" width={640} height={640} src={'https://adboard-booking-cdn.mos.ap-southeast-2.sufybkt.com/adboard-booking-web/pexels-photo-1054417.720b02f9.jpeg-webimage'} alt="Billboard banner" />
        </div>
      </div>

      <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
        <HeroSearchForm />
      </div>
    </div>
  );
};

export default SectionHero2;
