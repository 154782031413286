import CardAuthorBox, { workspaceSelector } from "@/components/CardAuthorBox/CardAuthorBox";
import CardAuthorBox2 from "@/components/CardAuthorBox2/CardAuthorBox2";
import Heading from "@/components/Heading/Heading";
import { AuthorType } from "@/data/types";
import React, { FC } from "react";
import ButtonPrimary from "@/shared/Button/ButtonPrimary";
import {  useTypedQuery } from "@/hooks/useZeus";
import { WorkspaceType } from "@/generated/zeus";
import { Link } from "react-router-dom";

export interface SectionGridAuthorBoxProps {
  className?: string;
  authors?: AuthorType[];
  boxCard?: "box1" | "box2";
  gridClassName?: string;
}

const SectionGridAuthorBox: FC<SectionGridAuthorBoxProps> = ({
  className = "",
  boxCard = "box1",
  gridClassName = "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 ",
}) => {

  const {data} = useTypedQuery({
    workspaces:[{
      where:{
        type:{
          in:[WorkspaceType.PROD]
        }
      }      
    },workspaceSelector]
  })

  return (
    <div
      className={`nc-SectionGridAuthorBox relative ${className}`}
      data-nc-id="SectionGridAuthorBox"
    >
      <Heading desc="Rating based on listings published" isCenter>
        Top publisher of the month
      </Heading>
      <div className={`grid gap-6 md:gap-8 ${gridClassName}`}>
        {data?.workspaces
          .filter(item=>item.adBoards.length)
          .sort((a,b)=>b.adBoards.length-a.adBoards.length) 
          .map((author, index) =>
            boxCard === "box2" ? (
              <CardAuthorBox2 key={author.key} author={author} />
            ) : (
              <CardAuthorBox
                index={index < 3 ? index + 1 : undefined}
                key={author.key}
                author={author}
              />
            )
          )}
      </div>
      <div className="mt-16 flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-5">
        {/* <ButtonSecondary>Show me more </ButtonSecondary> */}
        <Link to={"/publisher"}>
          <ButtonPrimary>Become a publisher</ButtonPrimary>
        </Link>
      </div>
    </div>
  );
};

export default SectionGridAuthorBox;
