import Logo from "@/shared/Logo/Logo";
import { CustomLink } from "@/data/types";
import React from "react";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "1",
    title: "Explore",
    menus: [
      { href: "https://screens.adboardbooking.com", label: "Manage your adboard" },
      { href: "/publisher", label: "Publisher homepage" },
      { href: "/adboards", label: "All adboards" },
      { href: "/faqs", label: "FAQs" },
    ],
  },
  {
    id: "2",
    title: "Social",
    menus: [
      { label: "Youtube",  href: "https://www.youtube.com/channel/UCREoVZ-9TLJ4o-6VrJK-DEw" },
      { label: "Instagram",  href: "https://www.instagram.com/adboardbooking/" },
      { label: "LinkedIn",  href: "https://www.linkedin.com/company/adboard-booking/" },
    ],
  },
  {
    id: "2",
    title: "About",
    menus: [
      { href: "/about", label: "About Us" },
      { href: "/contact", label: "Contact" },
      { href: "/sitemap", label: "Sitemap" },
      { href: "/blogs", label: "Blogs" },
    ],
  },
  {
    id: "4",
    title: "Policy",
    menus: [
      { href: "/delivery.html", label: "Shipping & Delivery" },
      { href: "/privacy-policy.html", label: "Privacy Policy" },
      { href: "/cancellation.html", label: "Cancellation & Refund policy" },
      { href: "/terms.html", label: "Terms & Condition" },
    ],
  },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <>
      <div className="nc-Footer relative py-2 lg:py-10 border-t border-neutral-200 dark:border-neutral-700">
        <div className="container mt-4 lg:mt-0 grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
          <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
            <div className="col-span-2 md:col-span-1">
              <Logo className="-mt-5"/>
            </div>
            <div className="col-span-2 flex items-center md:col-span-3">
              {/* <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" /> */}
            </div>
          </div>
          {widgetMenus.map(renderWidgetMenuItem)}
        </div>
      </div>
      <div className="text-sm text-center text-neutral-6000 pb-4">
        © 2022 AdBoard Booking. All rights reserved
      </div>
    </>
  );
};

export default Footer;
