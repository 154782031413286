import React, { FC } from "react";

import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import {SocialLogin} from "../../components/SocialLogin";

export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const history = useNavigate();

  function handleSuccess(){
    history('/')
  }

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login | AdBoard Booking</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center ">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <SocialLogin onSuccess={handleSuccess}/>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
