import BackgroundSection from "@/components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "@/components/BgGlassmorphism/BgGlassmorphism";
import SectionHowAdboardWork from "@/components/SectionHowItWork/SectionHowAdboardWork";
import { Helmet } from "react-helmet";
import SectionHero from "../PageAbout/SectionHero";
import ContactUs from "../PageContact/ContactUs";
import PageSubcription from "./PageSubcription";
import SectionMediaPlayer from "./SectionMediaPlayer";
import SectionBecomeAnPublisher from "./SectionBecomeAnPublisher";

import React from 'react';
import { Button } from '@/components/ui/button';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '@/components/ui/card';
import { CheckCircle, Monitor, Users, ShieldCheck, Zap, ChartBar, Folder } from 'lucide-react';

function OtherSection() {
  return (
    <div className='container mx-auto px-4 py-8'>
      <header className='text-center mb-12'>
        <h1 className='text-4xl font-bold mb-4'>Welcome to AdBoard Booking</h1>
        <p className='text-xl text-gray-600'>Your go-to platform for streamlined outdoor advertising management</p>
      </header>

      <section className='mb-12'>
        <h2 className='text-2xl font-semibold mb-4'>Our Key Features</h2>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
          {[
            { title: 'Anywhere, Anytime Access', icon: <Monitor className='h-6 w-6' /> },
            { title: 'Seamless Collaboration', icon: <Users className='h-6 w-6' /> },
            { title: 'Data Security', icon: <ShieldCheck className='h-6 w-6' /> },
            { title: 'Unlimited Potential', icon: <Zap className='h-6 w-6' /> },
            { title: 'Real-Time Campaign Tracking', icon: <ChartBar className='h-6 w-6' /> },
            { title: 'Comprehensive Client Management', icon: <Folder className='h-6 w-6' /> }
          ].map((feature, index) => (
            <Card key={index}>
              <CardHeader>
                <CardTitle className='flex items-center'>
                  {feature.icon}
                  <span className='ml-2'>{feature.title}</span>
                </CardTitle>
              </CardHeader>
            </Card>
          ))}
        </div>
      </section>

      <section className='mb-12'>
        <h2 className='text-2xl font-semibold mb-4'>How AdBoard Booking Works</h2>
        <ol className='list-decimal list-inside space-y-2'>
          <li>Access Anywhere: Log in to our system from any device, anywhere in the world.</li>
          <li>Effortless Proposals: Create multi-city proposals and block inventories instantly.</li>
          <li>Precise Invoicing: Our system integrates flex installation dates with invoicing, ensuring accurate billing.</li>
          <li>Centralized Information: Access all client data and campaign details from a single dashboard.</li>
          <li>Visual Campaign Tracking: View thousands of campaign photographs on a single screen to monitor mounting and unmounting activities.</li>
        </ol>
      </section>

      <section className='mb-12'>
        <h2 className='text-2xl font-semibold mb-4'>Why Choose AdBoard Booking?</h2>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
          {[
            { title: 'Efficiency', description: 'Streamline your outdoor advertising operations and boost productivity.' },
            { title: 'Flexibility', description: 'Work from anywhere, anytime, with our cloud-based platform.' },
            { title: 'Scalability', description: 'Grow your business without worrying about user or inventory limits.' },
            { title: 'Transparency', description: 'Provide clients with real-time updates and easy access to campaign information.' }
          ].map((item, index) => (
            <Card key={index}>
              <CardHeader>
                <CardTitle className='flex items-center'>
                  <CheckCircle className='mr-2 h-5 w-5 text-green-500' />
                  {item.title}
                </CardTitle>
              </CardHeader>
              <CardContent>
                <CardDescription>{item.description}</CardDescription>
              </CardContent>
            </Card>
          ))}
        </div>
      </section>

      <section className='mb-12'>
        <h2 className='text-2xl font-semibold mb-4'>Our Services for Residential Societies</h2>
        <div className='space-y-4'>
          <Card>
            <CardHeader>
              <CardTitle>Billboard Advertisement Infrastructure Setup</CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription>
                We help residential societies set up billboard advertisement infrastructure to generate additional income.
              </CardDescription>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Booking Management Software</CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription>
                Our software efficiently manages booking processes for advertisement slots.
              </CardDescription>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Advertiser Marketplace</CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription>
                We connect residential societies with advertisers through our marketplace, ensuring a steady stream of revenue.
              </CardDescription>
            </CardContent>
          </Card>
        </div>
      </section>

      <section className='text-center'>
        <h2 className='text-2xl font-semibold mb-4'>Experience the future of outdoor advertising management</h2>
        <a href="https://screens.adboardbooking.com" target="_blank">
          <Button size='lg'>Sign Up Today</Button>
        </a>
      </section>
    </div>
  );
}

function PageHomePublisher() {

  return (
    <div className="nc-PageHome3 relative overflow-hidden">
      <Helmet>
        <title>Publisher | AdBoard Booking</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      {/* SECTION HERO */}

      {/* <SectionOurFeatures type="type2" rightImg={rightImgPng} /> */}
      <div className="container relative space-y-4 mb-24 ">
        <div className="relative lg:py-8 pb-8 lg:mt-8">
          <BackgroundSection />
          <SectionHero />
        </div>
        <OtherSection/>
        <SectionBecomeAnPublisher/>
        {/* <FeaturesForPublishers className="lg:pb-8 lg:py-8"/> */}
        {/* <SectionBecomePublisher/> */}

        <div className="relative lg:py-8 pb-8 lg:mt-8">
          <BackgroundSection />
          {/* <FeaturesForPublishers className="lg:pb-8 py-0"/> */}
          <SectionHowAdboardWork/>
        </div>
       
        {/* <PricingComponent /> */}
        
        {/* SECTION */}
        <PageSubcription title={"Adboard Management Packages"} subtitle="Revolutionize your advertising strategy with our comprehensive adboard management solutions. Choose a package that fits your needs and scale your business with ease."/>
       
        <div className="relative py-16">
          <BackgroundSection />
          {/* <FAQ/> */}
          <SectionMediaPlayer/>
        </div>
        <ContactUs/>
        
      </div>
    </div>
  );
}

export default PageHomePublisher;
